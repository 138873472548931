import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/Devfest2024/Devfest2024/src/layout/mdx.tsx";
export const metadata = {
  title: "Mentions Légales"
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PrimarySection = makeShortcode("PrimarySection");
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PrimarySection mdxType="PrimarySection">
      <h2>{`GDG Nantes`}</h2>
      <p>{`Association loi 1901`}</p>
      <p>{`Siège social : 2 Pl. Louis Daubenton, 44100 Nantes`}</p>
      <p>{`SIRET : 81203357900028`}</p>
      <p>{`Présidente : Annabelle Koster`}</p>
      <h2>{`Hébergement`}</h2>
      <p>{`Ce site est hébergé par Google Cloud Platform, rue de Londres 75009 Paris`}</p>
      <h2>{`Conception et réalisation`}</h2>
      <p>{`Direction éditoriale : GDG Nantes`}</p>
      <p>{`Photographies : GDG Nantes`}</p>
      <p>{`Développement et intégration : GDG Nantes`}</p>
      <p>{`Web design : GDG Nantes`}</p>
      <h2>{`Confidentialité`}</h2>
      <p>{`La loi Informatique et Libertés du 06 janvier 1978 vous permet à tout moment de modifier ou supprimer les renseignements liés vous concernant et stockés dans nos bases de données. GDG Nantes respecte la législation française sur la protection de la vie privée.`}</p>
      <p>{`Selon l’article 34 de la loi « Informatique et Libertés », vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données qui vous concernent. Pour exercer ce droit, merci de nous contacter directement à l’adresse `}<a parentName="p" {...{
          "href": "mailto:mydata@gdgnantes.com"
        }}>{`mydata@gdgnantes.com`}</a></p>
    </PrimarySection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      